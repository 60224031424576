'use client';

import { clsx } from 'clsx';
import { useAtom } from 'jotai';
import { CSSProperties, useEffect, useState, memo } from 'react';

import { IconButton } from '@daouoffice/ui/lib/foundation/Button/IconButton';
import { useDebounce } from '@daouoffice/ui/lib/hooks';
import { ClearIcon } from '@daouoffice/ui/lib/icons/dop/24';
import { useTranslation } from '../../lib/i18n/client/useTranslation';

import NodeList from './NodeList';
import SearchResult from './SearchResult';
import SelectedNodes from './SelectedNodes';
import {
  treesAtom,
  searchedTreesAtom,
  searchedUsersAtom,
  syncNodes,
  getDisplaySelectedNodes,
} from './Store';
import {
  // getCompanyGroupOrg,
  // getCompanyOrgById,
  getOrg,
  getUserOrg,
  searchDepartments,
  searchUsers,
} from './getOrg';
import { NodeProps, OrgResponseData, PageResponseProps } from './interface';
import { DEPARTMENT, NORMAL } from './constants';
import './organization.css';
import { useQuery } from '@tanstack/react-query';
import { ApiUrlGenerator, ExtendedApiParams } from './Provider';
// import { useAuthContext } from '../../lib/auth/client';

export interface Props<T> {
  title?: string;
  type: string;
  apiUrl?: ApiUrlGenerator<T>;
  apiParams?: ExtendedApiParams<T>;
  dataConverter: (res: OrgResponseData) => NodeProps[];
  // setSelectedNodes: (nodes: NodeProps[]) => NodeProps[];
  useHeader?: boolean;
  useFooter?: boolean;
  useMultiSelect?: boolean;
  useResize: boolean;
  useGroupOrg?: boolean;
  //TODO: 추후 분기처리 필요
  companyId?: number;
  style?: CSSProperties;
  onClose?: () => void;
  onConfirm?: (nodes: NodeProps[]) => void;
}

export function OrgChart({
  title,
  type,
  apiUrl,
  apiParams,
  dataConverter,
  // setSelectedNodes,
  useHeader,
  useFooter,
  useMultiSelect,
  useResize,
  useGroupOrg = false,
  //TODO: 추후 분기처리 필요
  // companyId,
  onClose,
  onConfirm,
  style = {
    width: '360px',
    top: 'auto',
    left: 'auto',
    bottom: 'auto',
    borderRadius: 'unset',
    position: 'unset',
  },
}: Props<unknown>) {
  const { t } = useTranslation();
  title = title || t('조직도');

  const [trees, setTrees] = useAtom(treesAtom);
  const [isSearch, setIsSearch] = useState(false);
  const [searchedTrees, setSearchTrees] = useAtom(searchedTreesAtom);
  const [searchedUsers, setSearchedUsers] = useAtom(searchedUsersAtom);
  // const [searchedUsers, setUsers] = useState<NodeProps[]>([]);
  const [page, setPage] = useState(0);
  const [isLastPage, setIsLastPage] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const selectedNodes = getDisplaySelectedNodes(
    trees,
    searchedTrees,
    searchedUsers,
    type,
  );
  // const { me } = useAuthContext();

  const { data: orgData } = useQuery({
    queryKey: ['getOrg', type],
    queryFn: async () => {
      //TODO: 추후 분기처리 필요
      if (apiUrl) {
        return getOrg(apiUrl(apiParams), dataConverter);
      } else {
        return getUserOrg(type);
      }
      // if ((useGroupOrg || companyId) && me) {
      //   if (useGroupOrg) {
      //     return getCompanyGroupOrg(me.companyGroupId);
      //   } else if (companyId) {
      //     return getCompanyOrgById(me.companyGroupId, companyId);
      //   }
      // } else {
      //   return getUserOrg(type);
      // }
    },
  });

  useEffect(() => {
    if (orgData) {
      setTrees(orgData);
    }
    // setSelectedNodes(selectedNodes);
  }, [orgData, setTrees]);

  const debouncedHandleSearch = useDebounce(
    (e: React.FormEvent<HTMLInputElement>) => {
      handleSearch(e).catch((e) => console.log(e));
    },
    250,
  );

  const debouncedHandleScroll = useDebounce(
    (e: React.UIEvent<HTMLInputElement>) => {
      handleScroll(e).catch((e) => console.log(e));
    },
    250,
  );

  const clearSerch = () => {
    setSearchTrees([]);
    setSearchedUsers([]);
    setPage(0);
    setKeyword('');
    setIsLastPage(false);
  };

  const handleSearch = async (e: React.FormEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    const currentKeyword: string = target.value;
    const hasKeyword = !!currentKeyword;
    if (isSearch !== hasKeyword) setIsSearch(hasKeyword);
    if (!currentKeyword) {
      clearSerch();
      return;
    }
    setIsSearching(true);
    const searchTrees: NodeProps[] = await searchDepartments(currentKeyword);
    if (type !== DEPARTMENT) {
      const usersResponse: PageResponseProps = (await searchUsers(
        currentKeyword,
      )) as PageResponseProps;
      setSearchedUsers(syncNodes(usersResponse.elements, trees));
      setIsLastPage(
        usersResponse.page.pageNumber + 1 === usersResponse.page.totalPages,
      );
    }
    setIsSearching(false);
    setSearchTrees(syncNodes(searchTrees, trees));
    setKeyword(currentKeyword);
  };

  const handleScroll = async (e: React.UIEvent<HTMLElement>) => {
    const el = e.target as HTMLInputElement;
    const isScrollBottom =
      el.scrollHeight - Math.ceil(el.scrollTop) - el.clientHeight < 1;
    if (!isSearch || isLastPage || !isScrollBottom) return;
    const nextPage = page + 1;
    const searchUsersResponse: PageResponseProps = (await searchUsers(
      keyword,
      nextPage,
    )) as PageResponseProps;
    setPage(nextPage);
    setSearchedUsers(
      syncNodes(searchedUsers.concat(searchUsersResponse.elements), trees),
    );
    setIsLastPage(
      searchUsersResponse.page.pageNumber + 1 ===
        searchUsersResponse.page.totalPages,
    );
  };

  const handleConfirm = () => {
    if (onConfirm) onConfirm(selectedNodes);
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent) => {
    console.log(e.dataTransfer.getData('node'));
  };

  return (
    <div
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      className={clsx(
        `dop_dialog dop_organization${useResize ? ' dialog_resizable' : ''}${useMultiSelect ? ' dop_selectable_org' : ''}`,
      )}
      style={style ?? {}}
    >
      <div className="wrap_popup">
        {useHeader && (
          <div className="wrap_popup_header">
            <h3>{title}</h3>
            <button type="button" className="btn_icon medium" onClick={onClose}>
              <i className="icon ic_medium">
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.4434 5.21094L5.44336 19.2109"
                    stroke="#2A353D"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                  <path
                    d="M5.44336 5.21094L19.4434 19.2109"
                    stroke="#2A353D"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></path>
                </svg>
              </i>
            </button>
          </div>
        )}
        {/* TODO : 테스트를 위해 임시로 overflow-y-auto 적용*/}
        <div className="wrap_popup_contents overflow-y-auto">
          {type !== NORMAL && useMultiSelect && <SelectedNodes />}
          <div className="wrap_search">
            <div className="group_input">
              <div className="input_search">
                <i className="icon ic_small">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M4.5596 4.5596C6.13496 2.98424 8.2716 2.09921 10.4995 2.09921C12.7274 2.09921 14.864 2.98424 16.4394 4.5596C18.0147 6.13496 18.8998 8.2716 18.8998 10.4995C18.8998 12.4253 18.2385 14.283 17.0416 15.7688L21.6364 20.3636C21.9879 20.7151 21.9879 21.2849 21.6364 21.6364C21.2849 21.9879 20.7151 21.9879 20.3636 21.6364L15.7688 17.0416C14.283 18.2385 12.4253 18.8998 10.4995 18.8998C8.2716 18.8998 6.13496 18.0147 4.5596 16.4394C2.98424 14.864 2.09921 12.7274 2.09921 10.4995C2.09921 8.2716 2.98424 6.13496 4.5596 4.5596ZM10.4995 3.89921C8.74899 3.89921 7.07018 4.5946 5.83239 5.83239C4.5946 7.07018 3.89921 8.74899 3.89921 10.4995C3.89921 12.25 4.5946 13.9288 5.83239 15.1666C7.07018 16.4044 8.74899 17.0998 10.4995 17.0998C12.25 17.0998 13.9288 16.4044 15.1666 15.1666C16.4044 13.9288 17.0998 12.25 17.0998 10.4995C17.0998 8.74899 16.4044 7.07018 15.1666 5.83239C13.9288 4.5946 12.25 3.89921 10.4995 3.89921Z"
                      fill="#AAAAAA"
                    ></path>
                  </svg>
                </i>
                <input
                  className="input_txt body_medium regular"
                  type="search"
                  placeholder={t('이름, 부서, 직위, 전화번호 검색')}
                  onChange={debouncedHandleSearch}
                />
                <IconButton title="" className="clear">
                  <ClearIcon />
                </IconButton>
              </div>
            </div>
          </div>
          <div className="container" onScroll={debouncedHandleScroll}>
            {!isSearch && (
              <NodeList
                nodes={trees}
                useGroupOrg={useGroupOrg} //TODO: 추후 분기처리 필요
              ></NodeList>
            )}
            {isSearch && (
              <SearchResult
                searchTrees={searchedTrees}
                searchUsers={searchedUsers}
                isSearching={isSearching}
              ></SearchResult>
            )}
          </div>
        </div>
        {useFooter && (
          <div className="wrap_popup_button">
            <button className="btn_medium solid rect" onClick={handleConfirm}>
              <span className="txt">
                {t('확인')} {selectedNodes.length ? selectedNodes.length : ''}
              </span>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default memo(OrgChart);
