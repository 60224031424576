// 경로 및 URL들
export const LOGIN_PAGE_URL = '/login';
export const TFA_PAGE_URL = '/tfa';
export const FORCE_PASSWORD_CHANGE_PAGE_URL = '/login/pw-change';
export const API_TOKEN_REFRESH_URL = '/api/portal/public/auth/refresh/login';
export const GRAPHQL_ENDPOINT = '/api/portal/graphql';

// 오류 코드 정의
export const ERR_ACCESS_TOKEN_NOT_FOUND = 'ERR_ACCESS_TOKEN_NOT_FOUND'; // 액세스 토큰이 존재하지 않을 경우
export const ERR_REFESH_TOKEN_NOT_ALLOWED = 'ERR_REFESH_TOKEN_NOT_ALLOWED'; // 리프레시 토큰의 갱신이 거부되거나 정상적으로 진행되지 않은 경우
export const ERR_SYSTEM_ERROR = 'ERR_SYSTEM_ERROR';

// HTTP Status Codes
export const HttpStatusCodes = {
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
  BAD_GATEWAY: 502,
  GATEWAY_TIMEOUT: 504,
};

// 서비스 에러 코드 (라우팅 기준)
// 참고: https://wiki.daou.co.kr/pages/viewpage.action?pageId=106955195
export const ServiceErrorCodes = {
  'ROUTE-0001': {
    code: 'ROUTE-0001',
    status: HttpStatusCodes.UNAUTHORIZED,
    message: 'Concurrent access limit',
    description: '동시 접속제한',
  },
  'ROUTE-0002': {
    code: 'ROUTE-0002',
    status: HttpStatusCodes.UNAUTHORIZED,
    message: 'Invalidated token',
    description: '무효화 된 토큰',
  },
  'ROUTE-0003': {
    code: 'ROUTE-0003',
    status: HttpStatusCodes.FORBIDDEN,
    message: 'Unauthorized user',
    description: '권한 없음',
  },
  'ROUTE-0004': {
    code: 'ROUTE-0004',
    status: HttpStatusCodes.UNAUTHORIZED,
    message: 'Invalid token',
    description: '유효하지 않은 형식의 토큰',
  },
  'ROUTE-0005': {
    code: 'ROUTE-0005',
    status: HttpStatusCodes.UNAUTHORIZED,
    message: 'No token',
    description: '토큰 없음',
  },
  'ROUTE-0006': {
    code: 'ROUTE-0006',
    status: HttpStatusCodes.UNAUTHORIZED,
    message: 'Expired',
    description: '토큰 만료',
  },
  'ROUTE-0007': {
    code: 'ROUTE-0007',
    status: HttpStatusCodes.UNAUTHORIZED,
    message: 'OTP required',
    description: 'OTP 인증 필요',
  },
  'ROUTE-0008': {
    code: 'ROUTE-0008',
    status: HttpStatusCodes.BAD_GATEWAY,
    message: 'Undefined cluster',
    description: '정의되지 않은 Cluster Name',
  },
  'ROUTE-0009': {
    code: 'ROUTE-0009',
    status: HttpStatusCodes.NOT_FOUND,
    message: 'URL pattern matching failed',
    description: '라우팅 불가 (정의되지 않은 URL Path 등..)',
  },
  'ROUTE-0010': {
    code: 'ROUTE-0010',
    status: HttpStatusCodes.GATEWAY_TIMEOUT,
    message: 'Connection timed out',
    description: '내부 서비스 TCP Connection 타임아웃',
  },
  'ROUTE-0011': {
    code: 'ROUTE-0011',
    status: HttpStatusCodes.INTERNAL_SERVER_ERROR,
    message: 'Filter processing error',
    description: 'Gateway 필터 처리 간 내부 오류',
  },
  'ROUTE-0012': {
    code: 'ROUTE-0012',
    status: HttpStatusCodes.GATEWAY_TIMEOUT,
    message: 'Timed out',
    description: 'Gateway 필터 처리 간 내부 오류',
  },
  'ROUTE-0013': {
    code: 'ROUTE-0013',
    status: HttpStatusCodes.UNAUTHORIZED,
    message: 'Invalid data of user',
    description:
      '토큰의 User 정보를 통해 Redis DB에서 얻은 값에 문제가 있을 경우 발행하는 에러',
  },
  'ROUTE-0014': {
    code: 'ROUTE-0014',
    status: HttpStatusCodes.UNAUTHORIZED,
    message: ' Not found token userdata from cache server',
    description:
      '토큰의 User 정보를 통해 Redis DB에서 얻은 값에 문제가 있을 경우 발행하는 에러',
  },
  'ROUTE-0900': {
    code: 'ROUTE-0900',
    status: HttpStatusCodes.UNAUTHORIZED,
    message: 'Secondary authentication is required for doacc',
    description: '경영업무포털접근을 위한 2차인증 필요',
  },
  'ROUTE-0901': {
    code: 'ROUTE-0901',
    status: HttpStatusCodes.UNAUTHORIZED,
    message: 'Forced password change required',
    description: '비밀번호 강제변경 필요 (변경 연장 불가능한 경우)',
  },
  'ROUTE-0902': {
    code: 'ROUTE-0902',
    status: HttpStatusCodes.UNAUTHORIZED,
    message: 'Secondary authentication is required',
    description: '2차인증 필요',
  },
  'ROUTE-0903': {
    code: 'ROUTE-0903',
    status: HttpStatusCodes.UNAUTHORIZED,
    message: 'Forced password change required',
    description: '비밀번호 강제변경 필요 (변경 연장 가능한 경우)',
  },
  'ROUTE-0904': {
    code: 'ROUTE-0904',
    status: HttpStatusCodes.UNAUTHORIZED,
    message: 'Change Password',
    description: '비밀번호 변경',
  },
  'ROUTE-0905': {
    code: 'ROUTE-0905',
    status: HttpStatusCodes.UNAUTHORIZED,
    message: 'Logout',
    description: '로그아웃',
  },
  'ROUTE-0906': {
    code: 'ROUTE-0906',
    status: HttpStatusCodes.UNAUTHORIZED,
    message: 'Automatic logout',
    description: '자동 로그아웃',
  },
  'ROUTE-0907': {
    code: 'ROUTE-0907',
    status: HttpStatusCodes.UNAUTHORIZED,
    message: 'Abnormal user (delete/suspend)',
    description: '비정상 사용자 (삭제/정지)',
  },
  'ROUTE-0908': {
    code: 'ROUTE-0908',
    status: HttpStatusCodes.UNAUTHORIZED,
    message: 'Company contract expiration',
    description: '회사 계약 만료',
  },
  'ROUTE-0909': {
    code: 'ROUTE-0909',
    status: HttpStatusCodes.UNAUTHORIZED,
    message: 'Duplicate login - log out of another account',
    description: '중복로그인-다른계정 로그아웃',
  },
  'ROUTE-0910': {
    code: 'ROUTE-0910',
    status: HttpStatusCodes.UNAUTHORIZED,
    message: 'Restrict user mobile access',
    description: '사용자 모바일 접근 제한',
  },
  'ROUTE-0911': {
    code: 'ROUTE-0911',
    status: HttpStatusCodes.UNAUTHORIZED,
    message: 'Block mobile device access',
    description: '모바일 기기 접속 차단',
  },
  'ROUTE-0912': {
    code: 'ROUTE-0912',
    status: HttpStatusCodes.UNAUTHORIZED,
    message: 'Dormant user',
    description: '휴면 사용자',
  },
  'ROUTE-0999': {
    code: 'ROUTE-0999',
    status: HttpStatusCodes.UNAUTHORIZED,
    message: 'Unknown block token',
    description: '사유가 정의되지 않은 차단 된 토큰 사용',
  },
  'ROUTE-9999': {
    code: 'ROUTE-0099',
    status: HttpStatusCodes.INTERNAL_SERVER_ERROR,
    message: 'Gateway internal server error',
    description: '정의되지 않은 에러',
  },
};

// Firebase 상수 정의
export const NEXT_APP_FIREBASE_API_KEY =
  'AIzaSyBaMwdRPEjbfFiR2D-aoEDCOBHtymGe0L4';
export const NEXT_APP_FIREBASE_AUTH_DOMAIN = 'fcm-test-a967c.firebaseapp.com';
export const NEXT_APP_FIREBASE_PROJECT_ID = 'fcm-test-a967c';
export const NEXT_APP_FIREBASE_MESSAGING_SENDER_ID = '860059643736';
export const NEXT_APP_FIREBASE_APP_ID =
  '1:860059643736:web:c7be3ad53c937319fc3f2d';
export const NEXT_PUBLIC_VAPID_KEY =
  'BOIuB7zAQtOZCqnlrRziGJOC8-ZBUuKCFJwRJ4IkcTJrX2KMVkNpdTmeluKiauOPlK82rVeVn3-ZCxExQ8t4oxU';
export const NEXT_APP_FIREBASE_STORAGE_BUCKET = 'fcm-test-a967c.appspot.com';
