/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';

import { IconButton } from '@daouoffice/ui/lib/foundation/Button/IconButton';
import { CheckBox } from '@daouoffice/ui/lib/foundation/CheckBox/CheckBox';
import { ChevronRightIcon } from '@daouoffice/ui/lib/icons/dop/24';

import {
  getAllSubNodes,
  // getCompanyGroupDepartment,
  getDepartment,
  getSubNode,
} from './getOrg';
import { NodeProps } from './interface';
import NodeList from './NodeList';
import { useOrgChartContext } from './Provider';
import { updateTrees, treesAtom, searchedTreesAtom } from './Store';
import { DEPARTMENT, MEMBER } from './constants';
// import { useAuthContext } from '../../lib/auth/client';

export interface Props {
  node: NodeProps;
  isSearch?: boolean;
  depth: number;
  isLoadedAll?: boolean;
  isActive: boolean;
  //TODO: 추후 분기처리 필요
  useGroupOrg?: boolean;
  // updateNodes: (node: NodeProps) => void;
}

const DeptNode = ({
  node,
  isSearch,
  depth,
  isLoadedAll = false,
  isActive,
  useGroupOrg,
  // updateNodes,
}: Props) => {
  const context = useOrgChartContext();
  const { onNodeClick } = context.actions;
  const { useMultiSelect, uuid, type, subNodeApiUrl, apiParams } =
    context.state;
  const [, setTrees] = useAtom(treesAtom);
  const [, setSearchTrees] = useAtom(searchedTreesAtom);
  const nodes = node.childrenList || [];
  const [isOpend, setIsOpened] = useState(!!nodes.length);
  const [isLoaded, setIsLoaded] = useState(!!nodes.length); // TODO 한번에 가져올때 하위 노드들에 isLoaded 작업 해줘야함.
  const [isLoadedAllState, setIsLoadedAll] = useState(isLoadedAll);
  const isChecked = node.selected;
  const hasAdditional = isSearch && !!node.parentName;
  // const { me } = useAuthContext();

  useEffect(() => {
    if (isLoadedAll) {
      setIsLoadedAll(true);
      setIsLoaded(true);
    }
  }, [isLoadedAll]);

  const toggleFold = async () => {
    setIsOpened(!isOpend);
    if (isLoaded) return;
    await getChildren();
  };

  const getChildren = async () => {
    if (isLoaded) return;
    setIsLoaded(true);
    const dept = subNodeApiUrl
      ? ((await getSubNode(node, subNodeApiUrl, apiParams)) as NodeProps)
      : ((await getDepartment(node.departmentId as number, type)) as NodeProps);
    if (dept.childrenList) {
      if (isSearch) {
        setSearchTrees((searchTrees) => updateTrees(searchTrees, dept));
      } else {
        setTrees((trees) => updateTrees(trees, dept));
      }
    }
  };

  const getAllChildrens = async () => {
    let childrenList = node.childrenList;
    if (!isLoadedAllState) {
      setIsLoadedAll(true);
      setIsLoaded(true);
      childrenList = await getAllSubNodes(node.departmentId as number, type);
    }
    const newNode = {
      ...node,
      childrenList,
      selected: !node.selected,
    };
    if (isSearch) {
      setSearchTrees((searchTrees) => updateTrees(searchTrees, newNode));
    } else {
      setTrees((trees) => updateTrees(trees, newNode));
    }
  };

  const toggleSelect = async () => {
    type === DEPARTMENT ? await getChildren() : await getAllChildrens();
    // updateNodes();
  };

  const clickNode = () => {
    console.log('부서 프로필 오픈');
    if (onNodeClick && type !== MEMBER) onNodeClick(node);
  };

  return (
    <>
      <div className="subject" onKeyPress={() => {}} role="presentation">
        <IconButton
          title=""
          size="small"
          className="open"
          onClick={() => {
            toggleFold().catch((e) => console.log(e));
          }}
          aria-hidden="true"
        >
          <ChevronRightIcon rotate={isOpend ? 90 : 0} />
        </IconButton>
        <div className={`info${isActive ? ' active' : ''}`} onClick={clickNode}>
          <p className="body_medium semibold" title={node.name}>
            {node.name}
          </p>
          {hasAdditional && <p className="additional">{node.parentName}</p>}
          {useMultiSelect && (
            <CheckBox
              id={`${node.nodeType}_${node.departmentId}_${uuid}`}
              onChange={() => {
                toggleSelect().catch((e) => console.log(e));
              }}
              defaultChecked={isChecked}
            ></CheckBox>
          )}
        </div>
      </div>
      {isOpend && (
        <NodeList
          nodes={nodes}
          className="sub"
          depth={depth + 1}
          isSearch={isSearch}
          isLoadedAll={isLoadedAllState}
          useGroupOrg={useGroupOrg}
        ></NodeList>
      )}
    </>
  );
};

export default DeptNode;
