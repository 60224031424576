/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { useAtom } from 'jotai';
import DeptNode from './DeptNode';
import { NodeProps } from './interface';
import MemberNode from './MemberNode';
import { useOrgChartContext } from './Provider';
import {
  // treesAtom,
  // searchedTreesAtom,
  // updateTrees,
  // updateSelectedNodes,
  // selectedNodesAtom,
  lastSelectedNodeAtom,
  nodesAtom,
} from './Store';
import { isSameType, getNodeId } from './Util';
import { COMPANY, DEPARTMENT } from './constants';

export interface Props {
  node: NodeProps;
  isSearch?: boolean;
  depth: number;
  isLoadedAll?: boolean;
  //TODO: 추후 분기처리 필요
  useGroupOrg?: boolean;
}

const DEEP_DEPTH = 5;

const Node = ({ node, isSearch, depth, isLoadedAll, useGroupOrg }: Props) => {
  const context = useOrgChartContext();
  const {
    setLastSelectedNode,
    // setSelectedNodes: setSelectedNodesAction
  } = context.actions;
  const { type } = context.state;
  const [lastSelectedNode, setLastSelectedNodeAtom] =
    useAtom(lastSelectedNodeAtom);
  const { useMultiSelect } = context.state;
  // const [, setTrees] = useAtom(treesAtom);
  // const [, setSearchTrees] = useAtom(searchedTreesAtom);
  // const [, setSelectedNodes] = useAtom(selectedNodesAtom);
  const [, setNodes] = useAtom(nodesAtom);

  const isDepartment =
    node.nodeType === DEPARTMENT || node.nodeType === COMPANY;
  const isDeepDepth = depth >= DEEP_DEPTH;

  const updateNodes = (node: NodeProps) => {
    setNodes(node);
  };

  // const updateNodes = (nodeState = {}) => {
  //   setTrees((trees) =>
  //     updateTrees(trees, { ...node, selected: !node.selected, ...nodeState }),
  //   );
  //   setSearchTrees((trees) =>
  //     updateTrees(trees, { ...node, selected: !node.selected, ...nodeState }),
  //   );
  //   setSelectedNodes((nodes) => {
  //     const result = updateSelectedNodes(nodes, {
  //       ...node,
  //       selected: !node.selected,
  //     });
  //     if (setSelectedNodesAction) setSelectedNodesAction(result);
  //     return result;
  //   });
  // };

  const handleDragStart = (e: React.DragEvent) => {
    e.stopPropagation();
    console.log(node);
    e.dataTransfer.setData('node', JSON.stringify({ ...node }));
  };

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (isSameType(node.nodeType, type)) {
      if (setLastSelectedNode) setLastSelectedNode(node);
      if (!useMultiSelect) setLastSelectedNodeAtom(node);
    }
  };

  const isActive =
    !!lastSelectedNode && getNodeId(node) === getNodeId(lastSelectedNode);

  return (
    <>
      <li
        draggable
        onDragStart={handleDragStart}
        className="list"
        data-depth={depth}
        style={
          isDeepDepth
            ? { paddingLeft: '0px', touchAction: 'none' }
            : { touchAction: 'none' }
        }
        onClick={(e) => handleClick(e)}
      >
        {isDepartment && (
          <DeptNode
            node={node}
            isSearch={isSearch}
            depth={depth}
            isLoadedAll={isLoadedAll}
            // updateNodes={updateNodes}
            isActive={isActive}
            //TODO: 추후 분기처리 필요
            useGroupOrg={useGroupOrg}
          ></DeptNode>
        )}
        {!isDepartment && (
          <MemberNode
            node={node}
            updateNodes={updateNodes}
            isActive={isActive}
          ></MemberNode>
        )}
      </li>
    </>
  );
};

export default Node;
