import '@daouoffice/design/dist/foundation/checkbox.css';
import * as CheckboxPrimitives from './primitives';

export type TagType = 'div' | 'td';

export interface Props {
  /** @property ID */
  id: string;
  /** @property 초기 체크 상태 여부 지정 */
  defaultChecked?: boolean;
  /** @property 초기 체크 상태 여부 지정 */
  defaultDisabled?: boolean;
  /** @property 초기 체크 상태 여부 지정 */
  as?: TagType;
  /** @property 라벨 */
  label?: string;
  /** @property tab-index 값 (기본값: 0) */
  tabIndex?: number;
  /** @property className */
  className?: string;
  /** @property 변경 이벤트 핸들러 */
  onChange?: (checked?: boolean) => void;
  /** aria-checked 속성. Variation 확장용 */
  ariaChecked?: boolean;
  /** aria-disabled 속성. Variation 확장용 */
  ariaDisabled?: boolean;
  /** aria-required 속성. Variation 확장용 */
  ariaRequired?: boolean;
}

/**
 * @deprecated Use `@dop-ui/react/shared/ui/check-box` instead.
 */
export function CheckBox({
  id,
  as = 'div',
  label = '',
  defaultChecked = false,
  defaultDisabled = false,
  className,
  onChange,
  ...restProps
}: Props) {
  const handleOnChange = (checked: boolean) => {
    if (onChange) onChange(checked);
  };

  return (
    <CheckboxPrimitives.Root {...restProps} className={className} as={as}>
      <CheckboxPrimitives.Item
        id={id}
        name=""
        isChecked={defaultChecked}
        isDisabled={defaultDisabled}
        onChange={(checked: boolean) => handleOnChange(checked)}
      />
      <label htmlFor={id}>{label}</label>
    </CheckboxPrimitives.Root>
  );
}

export default CheckBox;
