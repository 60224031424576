import {
  COMPANY,
  DEPARTMENT,
  MEMBER,
  NORMAL,
  UNSPECIFIED_MEMBER,
} from './constants';
import { NodeProps } from './interface';

export const getNodeId = (node: NodeProps) => {
  return `${node.nodeType}_${node.userId || node.departmentId}`;
};

export const isSameType = (nodeType: string | undefined, orgType: string) => {
  if (orgType === NORMAL) return true;
  if (
    orgType === DEPARTMENT &&
    (nodeType === DEPARTMENT || nodeType === COMPANY)
  )
    return true;
  if (
    orgType === MEMBER &&
    (nodeType === MEMBER || nodeType === UNSPECIFIED_MEMBER)
  )
    return true;
  return false;
};

export const unique = <T>(
  array: T[],
  key: ((item: T) => string) | keyof T,
): T[] => {
  const seen = new Set<string>();

  return array.filter((item) => {
    const keyValue = typeof key === 'function' ? key(item) : String(item[key]);

    if (seen.has(keyValue)) {
      return false;
    } else {
      seen.add(keyValue);
      return true;
    }
  });
};
