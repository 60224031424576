import { useTranslation } from '../../lib/i18n/client/useTranslation';

import NodeList from './NodeList';
import { NodeProps } from './interface';

export interface Props {
  searchTrees: NodeProps[];
  searchUsers: NodeProps[];
  isSearching: boolean;
}

export default function SearchResult({
  searchTrees = [],
  searchUsers = [],
  isSearching,
}: Props) {
  const { t } = useTranslation();
  return (
    <>
      {!!searchTrees.length && (
        <>
          <p className="title body_small regular">{t('부서')}</p>
          <NodeList nodes={searchTrees} isSearch={true} />
        </>
      )}
      {!!searchUsers.length && (
        <>
          <p className="title body_small regular">{t('이름')}</p>
          <NodeList nodes={searchUsers} />
        </>
      )}
      {!searchTrees.length && !searchUsers.length && (
        <div className="no_data">
          <p className="body_medium regular">
            {isSearching ? t('검색중입니다') : t('검색결과가 없습니다')}
          </p>
        </div>
      )}
    </>
  );
}
