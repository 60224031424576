import { getSessionData } from '../../lib/auth/client/Provider';
import * as fetchClient from '../../lib/fetch/client';
import { ApiUrlGenerator, ExtendedApiParams } from './Provider';
import { DEPARTMENT } from './constants';

import {
  DepartmentResponseData,
  NodeProps,
  OrgResponseData,
  SearchDepartmentsResponseBody,
  SearchUsersResponseBody,
} from './interface';

export async function getUserOrg(type: string) {
  try {
    const myData = await getSessionData();

    console.log(myData);
    const resp = await fetchClient.fetch(
      `/api/portal/common/tree/user/${myData.id}${type === DEPARTMENT ? '?isOnlyDepartment=true' : ''}`,
    );
    if (!resp.ok) {
      throw new Error('조직도 조회 API 요청 실패.');
    }
    const res = (await resp.json()) as OrgResponseData;
    return res.data.elements;
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function getOrg(
  url: string,
  dataConverter: (res: OrgResponseData) => Array<NodeProps>,
) {
  try {
    const resp = await fetchClient.fetch(url);
    if (!resp.ok) {
      throw new Error('조직도 조회 API 요청 실패.');
    }
    const res = (await resp.json()) as OrgResponseData;
    const result = dataConverter(res);
    // const result = Array.isArray(res.data) ? res.data : [res.data];
    console.log(result);
    return result;
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function getCompanyOrg() {
  try {
    const resp = await fetchClient.fetch(`/api/portal/admin/tree/company`);
    if (!resp.ok) {
      throw new Error('조직도 조회 API 요청 실패.');
    }
    const res = (await resp.json()) as OrgResponseData;
    return res.data.elements;
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function getCompanyOrgById(
  companyGroupId?: number | string,
  companyId?: number,
) {
  try {
    const resp = await fetchClient.fetch(
      `/api/portal/setting/company-group/organization/tree/${companyGroupId}/${companyId}`,
    );
    if (!resp.ok) {
      throw new Error('조직도 조회 API 요청 실패.');
    }
    const res = (await resp.json()) as { data: NodeProps };
    const response: NodeProps[] = [res.data];

    return response;
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function getCompanyGroupOrg(companyGroupId?: number | string) {
  try {
    const resp = await fetchClient.fetch(
      `/api/portal/admin/tree/company-group/${companyGroupId}`,
    );
    if (!resp.ok) {
      throw new Error('조직도 조회 API 요청 실패.');
    }
    const res = (await resp.json()) as OrgResponseData;
    return res.data.elements;
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function getCompanyGroupDepartment(
  deptId: number,
  type: string,
  companyGroupId?: number | string,
) {
  try {
    const resp = await fetchClient.fetch(
      `/api/portal/setting/company-group/${companyGroupId}/organization/tree/department/${deptId}${type === DEPARTMENT ? '?isOnlyDepartment=true' : ''}`,
    );
    if (!resp.ok) {
      throw new Error('조직도 조회 API 요청 실패.');
    }
    const res = (await resp.json()) as { data: NodeProps };
    return res.data;
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function getDepartment(deptId: number, type: string) {
  const resp = await fetchClient.withAuth(
    `/api/portal/common/tree/department/${deptId}${type === DEPARTMENT ? '?isOnlyDepartment=true' : ''}`,
  );
  if (!resp.ok) {
    console.log(resp);
    return [];
  }
  const { data }: DepartmentResponseData =
    (await resp.json()) as DepartmentResponseData;
  return data;
}

export async function getSubNode<T>(
  node: NodeProps,
  subNodeApiUrl: ApiUrlGenerator<T>,
  apiParams: ExtendedApiParams<T>,
) {
  const url = subNodeApiUrl ? subNodeApiUrl(apiParams, node) : '';
  console.log(url);
  const resp = await fetchClient.withAuth(url);
  if (!resp.ok) {
    console.log(resp);
    return [];
  }
  const { data }: DepartmentResponseData =
    (await resp.json()) as DepartmentResponseData;
  return data;
}

export async function searchUsers(keyword: string, page = 0, size = 20) {
  const resp = await fetchClient.withAuth(
    `/api/portal/common/tree/search/member?keyword=${keyword}&page=${page}&size=${size}`,
  );
  if (!resp.ok) {
    console.log(resp);
    return [];
  }
  const { data }: SearchUsersResponseBody =
    (await resp.json()) as SearchUsersResponseBody;
  return data;
}

export async function searchDepartments(keyword: string) {
  const resp = await fetchClient.withAuth(
    `/api/portal/common/tree/search/department?keyword=${keyword}`,
  );
  if (!resp.ok) {
    console.log(resp);
    return [];
  }
  const { data }: SearchDepartmentsResponseBody =
    (await resp.json()) as SearchDepartmentsResponseBody;
  return data.elements;
}

export async function getAllSubNodes(deptId: number, type: string) {
  const resp = await fetchClient.withAuth(
    `/api/portal/common/tree/department/all/${deptId}${type === DEPARTMENT ? '?isOnlyDepartment=true' : ''}`,
  );
  if (!resp.ok) {
    console.log(resp);
    return [];
  }
  const res = (await resp.json()) as OrgResponseData;
  return res.data.elements;
}
