import { createContext, Dispatch, PropsWithChildren, useContext } from 'react';
import { NodeProps } from './interface';
import { SetStateAction } from 'jotai';
import { NORMAL } from './constants';

interface ApiParams {}

export type ExtendedApiParams<T> = (ApiParams & Partial<T>) | undefined;

export type ApiUrlGenerator<T> =
  | ((apiParams?: ExtendedApiParams<T>, node?: NodeProps) => string)
  | undefined;

export interface OrgChartState<T> {
  uuid: string;
  type: string;
  // apiUrl: T;
  subNodeApiUrl: ApiUrlGenerator<T>;
  apiParams: ExtendedApiParams<T>;
  useMultiSelect: boolean;
  usePresence: boolean;
}

export interface OrgChartActions {
  onNodeClick?: (node: NodeProps) => void;
  setSelectedNodes?: (nodes: NodeProps[]) => NodeProps[];
  setLastSelectedNode?:
    | Dispatch<SetStateAction<NodeProps | undefined>>
    | undefined;
}

export const OrgChartStateContext = createContext<OrgChartState<unknown>>({
  uuid: '',
  type: NORMAL,
  // apiUrl: '',
  subNodeApiUrl: () => '',
  apiParams: {},
  useMultiSelect: true,
  usePresence: false,
});

export const OrgChartActionsContext = createContext<OrgChartActions>({
  onNodeClick: () => undefined,
  setSelectedNodes: (nodes: NodeProps[]) => nodes,
  setLastSelectedNode: () => undefined,
});

export interface ProviderProps<T> {
  uuid: string;
  type: string;
  apiUrl: T;
  subNodeApiUrl: ApiUrlGenerator<T>;
  apiParams: ExtendedApiParams<T>;
  setSelectedNodes: (nodes: NodeProps[]) => NodeProps[];
  onNodeClick?: (node: NodeProps) => void;
  setLastSelectedNode:
    | Dispatch<SetStateAction<NodeProps | undefined>>
    | undefined;
  useMultiSelect: boolean;
  usePresence?: boolean;
}

export function Provider<T>({
  uuid,
  type,
  apiUrl,
  subNodeApiUrl,
  apiParams,
  setSelectedNodes = (nodes: NodeProps[]) => nodes,
  onNodeClick,
  setLastSelectedNode,
  useMultiSelect,
  usePresence = false,
  children,
}: PropsWithChildren<ProviderProps<T>>) {
  const state = {
    type,
    apiUrl,
    subNodeApiUrl,
    apiParams,
    useMultiSelect,
    uuid,
    usePresence,
  };
  const actions = { onNodeClick, setLastSelectedNode, setSelectedNodes };

  return (
    <OrgChartStateContext.Provider value={state}>
      <OrgChartActionsContext.Provider value={actions}>
        {children}
      </OrgChartActionsContext.Provider>
    </OrgChartStateContext.Provider>
  );
}

export const useOrgChartStateContext = () => useContext(OrgChartStateContext);
export const useOrgChartActionsContext = () =>
  useContext(OrgChartActionsContext);
export const useOrgChartContext = () => {
  const state = useOrgChartStateContext();
  const actions = useOrgChartActionsContext();

  return {
    state,
    actions,
  };
};
