import React, { CSSProperties, Dispatch, SetStateAction } from 'react';
import { Provider } from 'jotai';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import App from './OrgChart';
import {
  ApiUrlGenerator,
  ExtendedApiParams,
  Provider as OrgChartProvider,
} from './Provider';
import { NodeProps } from './interface';
import { generateUUID } from '../../lib/utils/util';
import { NORMAL } from './constants';

export interface Props<T> {
  type?: string;
  title?: string;
  apiUrl?: ApiUrlGenerator<T>;
  subNodeApiUrl?: ApiUrlGenerator<T>;
  apiParams?: ExtendedApiParams<T>;
  dataConverter?: () => NodeProps[];
  useHeader?: boolean;
  useFooter?: boolean;
  useMultiSelect?: boolean;
  useResize?: boolean;
  useGroupOrg?: boolean;
  companyId?: number;
  // selectableNodeType?: string;
  setSelectedNodes?: (nodes: NodeProps[]) => NodeProps[];
  onClose?: () => void;
  onConfirm?: (nodes: NodeProps[]) => void;
  onNodeClick?: (node: NodeProps) => void;
  setLastSelectedNode?: Dispatch<SetStateAction<NodeProps | undefined>>;
  style?: CSSProperties;
}

export default function OrgChart<T>({
  type = NORMAL,
  title,
  apiUrl,
  subNodeApiUrl,
  apiParams,
  dataConverter = () => [],
  useHeader = true,
  useFooter = true,
  useMultiSelect = true,
  useResize = false,
  useGroupOrg = false,
  //TODO: 추후 분기처리 필요
  companyId,
  setSelectedNodes = (nodes: NodeProps[]) => nodes,
  // selectableNodeType = NORMAL,
  onClose,
  onConfirm,
  onNodeClick,
  setLastSelectedNode,
  style,
}: Props<T>) {
  const uuid = generateUUID();

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 60 * 1000,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <Provider>
        <OrgChartProvider
          type={type}
          onNodeClick={onNodeClick}
          setLastSelectedNode={setLastSelectedNode}
          setSelectedNodes={setSelectedNodes}
          useMultiSelect={useMultiSelect}
          apiUrl={apiUrl}
          apiParams={apiParams}
          subNodeApiUrl={subNodeApiUrl}
          // selectableNodeType={selectableNodeType}
          uuid={uuid}
        >
          <App
            title={title}
            type={type}
            apiUrl={apiUrl}
            apiParams={apiParams}
            dataConverter={dataConverter}
            useHeader={useHeader}
            useFooter={useFooter}
            useMultiSelect={useMultiSelect}
            useResize={useResize}
            //TODO: 추후 분기처리 필요
            companyId={companyId}
            useGroupOrg={useGroupOrg}
            style={style}
            onClose={onClose}
            onConfirm={onConfirm}
          ></App>
        </OrgChartProvider>
      </Provider>
    </QueryClientProvider>
  );
}
